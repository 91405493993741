import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/DefaultLayout.tsx";
import { graphql } from 'gatsby';
import { Callout, CalloutTitle, CalloutText } from '../../components/callout';
import { Box } from '@twilio-paste/box';
import { Paragraph } from '@twilio-paste/paragraph';
import { Stack } from '@twilio-paste/stack';
import { Flex } from '@twilio-paste/flex';
import { Anchor } from '@twilio-paste/anchor';
import { Grid } from '@twilio-paste/grid';
import { Card } from '@twilio-paste/card';
import { LinkExternalIcon } from '@twilio-paste/icons/esm/LinkExternalIcon';
import { Link } from 'gatsby';
export const pageQuery = graphql`
  {
    mdx(fields: {slug: {eq: "/illustrations/"}}) {
      fileAbsolutePath
      frontmatter {
        slug
        title
      }
      headings {
        depth
        value
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageAside = makeShortcode("PageAside");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <contentwrapper>
      <PageAside data={props.data.mdx} mdxType="PageAside" />
      <content>
        <h1>{props.pageContext.frontmatter.title}</h1>
        <p>{props.pageContext.frontmatter.description}</p>
        <hr></hr>
        <p>{`Illustration helps breathe life into our brand. It can take a complex idea and make it easier to understand. It is a tool that allows us to communicate our product in a way that feels human and fun.`}</p>
        <p>{`Our illustration style is carefully curated by the Twilio Brand team. While adding these elements to your UI, ensure that you are reviewing your work with them. A good place to start is by posting in #mkg-brand.`}</p>
        <p><a parentName="p" {...{
            "href": "https://www.twilio.com/brand/guidelines/illustration-guidelines"
          }}>{`Read the brand illustration guidelines`}</a></p>
        <p><a parentName="p" {...{
            "href": "https://library.twilio.com"
          }}>{`Browse the illustration library`}</a>{`. To log in to the library with your Twilio credentials, request application access through IT via `}<a parentName="p" {...{
            "href": "https://twilio.service-now.com"
          }}>{`ServiceNow`}</a>{` for "The Library (Bynder)".`}</p>
        <h3>{`General guidelines for product UIs`}</h3>
        <ul>
          <li parentName="ul">{`Only make necessary and thoughtful adjustments to the illustration colors. When doing so, ensure that you’re using Paste's palette.`}</li>
          <li parentName="ul">{`Do not add extraneous elements to the illustrations. You may remove unnecessary elements, but do so while maintaining the thematic feel that the illustration is trying to convey.`}</li>
          <li parentName="ul">{`We recommend using SVGs over PNGs when possible, in order to improve performance and avoid blurring.`}</li>
        </ul>
        <h3>{`Accessibility`}</h3>
        <ul>
          <li parentName="ul"><strong parentName="li">{`Aim to pair your illustrations with text:`}</strong>{` Remember that different cultures may interpret the same image in different ways, so despite our best intentions, it is helpful to provide adjoining text.`}</li>
          <li parentName="ul"><strong parentName="li">{`Illustrations shouldn't include navigational interactions:`}</strong>{` Illustrations should not have interactions such as click or hover behavior that is essential to navigation and usage of the product. Instead they should be wrapped with an (or, ideally, laid out with supporting) interactive element such as Anchor.`}</li>
          <li parentName="ul"><strong parentName="li">{`Give your illustrations a title:`}</strong>{` An SVG `}<inlineCode parentName="li">{`title`}</inlineCode>{` or image `}<inlineCode parentName="li">{`alt`}</inlineCode>{` text is required on non-decorative illustrations so assistive technology can infer equal meaning as a sighted user would. For example, avoid a title that reads "WhatsApp Illustration - 01". Instead a title that says "Get started with our WhatsApp API" is preferable.`}</li>
        </ul>
        <h3>{`Request new illustrations`}</h3>
        <p>{`The best way to request new illustrations is to file a request with the Brand team. You can create a new
`}<a parentName="p" {...{
            "href": "https://issues.corp.twilio.com/projects/MARK/issues"
          }}>{`request on Jira`}</a>{` (VPN required).`}</p>
      </content>
    </contentwrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      